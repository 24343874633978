@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.all-services-wrapper {
    .all-services {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -8px;
        margin-left: -8px;
        padding-left: 4px;
    }

    .spinner {
        margin: 0 auto;
        margin-top: 14%;
    }
}

.coming-soon-section {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    font-family: $sans-reg;
}

.coming-soon-header {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    font-family: $sans-reg;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #c0392b, #8e44ad, #2980b9, #27ae60);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.coming-soon-items {
    display: flex;
    align-items: center;
    background: #f9f9f9;
    padding: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.coming-soon-content {
    display: flex;
    align-items: center;
}

.gift-box-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.coming-soon-title {
    margin-bottom: 0px;
    font-size: 14px;
    font-family: $sans-reg;
    color: #333333;
}

.coming-soon-description {
    font-size: 14px;
    color: #333333;
    font-family: $sans-reg;
}